const ScrollLinkEnum = Object.freeze({
    ABOUT: "ABOUT",
    BUY: "BUY",
    TOKENOMICS: "TOKENOMICS",
    TEAM: "TEAM",
    ROADMAP: "ROADMAP",
    FAQ: "FAQ",
    NONE: "NONE"
})

export default ScrollLinkEnum;