import { observer } from "mobx-react";
import React from 'react';
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Spinner = observer(() => {
    const { uiStore } = useStores();
    const { isLoading } = uiStore;

    return (
        isLoading &&
        <div className={styles["spinner-container"]}>
            <img alt={"Spinner"} className={styles["spinner-image"]} src={"android-chrome-192x192.png"} />
        </div>
    )
});

export default Spinner;