import { observer } from "mobx-react";
import React, { } from 'react';
import styles from "./index.module.scss";
import { useStores } from "../../stores/RootStore";
import { ethers } from "ethers";
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

const RangeSlider = observer(() => {
    const { t } = useTranslation();
    const { chainStore } = useStores();
    const { sliderValue, setSliderValue } = chainStore;

    const handleChange = (event) => {
        setSliderValue(event.target.value);
    }

    return (
        <div className={styles["slider-wrapper"]}>
            <div className={styles["slidecontainer"]}>
                <div className={styles["slider-title"]}>
                    {t("rangeSlider.title")} : {sliderValue}
                </div>
                <div className={styles["slider-explain"]}>
                    {'(Move slider to increase)'}
                </div>
                <input type="range" min={1} max={10} value={sliderValue} onChange={handleChange}
                    className={styles["slider"]} id="myRange" />
            </div>
            <div className={styles["slider-title"]}>
                {t("rangeSlider.total")}<NumberFormat
                    value={(1 * sliderValue).toFixed(4)}
                    displayType={'text'}
                    thousandSeparator={true} />
                <div className={styles["currency"]}>{' MOVR'}</div>
            </div>
        </div>
    )
});

export default RangeSlider;
