import { ethers, utils } from "ethers";
import { action, makeObservable, observable } from "mobx";
import configData from "../constants/config-data.json";
import NotificationTypeEnum from "../enums/notification-type-enum";

export class ChainStore {
  provider = {};
  signer = {};
  walletAddress = "";
  walletBalance = 0;
  nftsOwnedResult = 0;
  chainId = 0;
  contract = {};
  soldNfts = 0;
  sliderValue = 1;
  leaderboard = [];

  currentPrice = configData.currentPrice;
  address = configData.address;
  acceptedTokenAddress = configData.address;
  PROJECT_ID = configData.projectId;
  abi = configData.abi;
  maxNfts = configData.maxNfts;

  defaultProvider = new ethers.getDefaultProvider(configData.defaultProvider);
  defaultContract = new ethers.Contract(this.address, this.abi, this.defaultProvider);

  nftDetailsArray = [];
  sortedNftDetailsArray = [];
  sortedOwnNftDetailsArray = [];

  errorMessage = {};
  uiStoreRef = null;

  constructor(rootStore) {
    makeObservable(this, {
      //observable state
      provider: observable,
      signer: observable,
      walletAddress: observable,
      contract: observable,
      walletBalance: observable,
      nftsOwnedResult: observable,
      chainId: observable,
      soldNfts: observable,
      sliderValue: observable,
      leaderboard: observable,
      sortedNftDetailsArray: observable,
      sortedOwnNftDetailsArray: observable,

      defaultProvider: observable,
      //actions
      setProvider: action,
      setSigner: action,
      setWalletAddress: action,
      setContract: action,
      setWalletBalance: action,
      setNftsOwnedResult: action,
      setChainId: action,
      setSoldNfts: action,
      setSliderValue: action,
      setSortedNftDetailsArray: action,
      setSortedOwnNftDetailsArray: action,

      connectToWallet: action,
      purchaseNft: action,
    });
    this.uiStoreRef = rootStore.uiStore;
    this.getNftDetailsArray();
    this.getTotalSold();
  }

  setProvider = (provider) => {
    this.provider = provider
  }

  setSigner = (signer) => {
    this.signer = signer
  }

  setWalletAddress = (walletAddress) => {
    this.walletAddress = walletAddress
  }

  setContract = (contract) => {
    this.contract = contract;
  }

  setWalletBalance = (walletBalance) => {
    this.walletBalance = walletBalance;
  }

  setNftsOwnedResult = (nftsOwnedResult) => {
    this.nftsOwnedResult = nftsOwnedResult;
  }

  setChainId = (chainId) => {
    this.chainId = chainId;
  }

  setContract = (contract) => {
    this.contract = contract;
  }

  setSoldNfts = (nfts) => {
    this.soldNfts = nfts;
  }

  setSliderValue = (value) => {
    this.sliderValue = value;
  }

  setSortedNftDetailsArray = (value) => {
    this.sortedNftDetailsArray = value;
  }

  setSortedOwnNftDetailsArray = (value) => {
    this.sortedOwnNftDetailsArray = value;
  }

  getTotalSold = async () => {
    try {
      const amountSold = await this.defaultContract.totalSupply();
      this.setSoldNfts(String(amountSold));
    } catch (e) {
      console.log(e);
    }
  };

  getNftDetailsArray = async () => {
    try {
      this.nftDetailsArray = [];
      this.leaderboard = await this.defaultContract.leaderBoard();
      this.leaderboard[0].map((tokenId, i) => {
        return fetch(`/jua5vR2ZjZ8BJspUvtmPSmh4Bc9VEWwLmLkepEYQejdntJ3PNTALULxbWJPQ3xQY/${tokenId.toString()}.json`)
          .then(response => response.json())
          .then((data) => {
            this.nftDetailsArray.push({
              image: `/images/legendaries/${tokenId.toString()}.png`,
              owner: this.leaderboard[1][i],
              tokenId: tokenId.toString(),
              power: data.farmer_power
            })
          })
          .then(() => {
            this.nftDetailsArray.sort((a, b) => b.power - a.power)
          })
          .then(() => {
            this.setSortedNftDetailsArray(this.nftDetailsArray);
            this.uiStoreRef.setIsLoading(false);
          })
          .catch((error) => {
            console.error(error)
          })
      })
    } catch (e) {
      console.log(e);
    }
  };

  getOwnNftDetailsArray = () => {
    this.uiStoreRef.setIsLoading(true);
    this.setSortedOwnNftDetailsArray(
      this.sortedNftDetailsArray.filter(n => {
        return n.owner.toLowerCase() == this.walletAddress.toLowerCase()
      }))
    this.uiStoreRef.setIsLoading(false);
  }

  connectToWallet = async () => {
    try {
      await window.ethereum.enable();
      this.setProvider(new ethers.providers.Web3Provider(window.ethereum));
      await this.provider.send("eth_requestAccounts", []);
      this.setSigner(this.provider.getSigner());
      this.setWalletAddress(this.provider.provider.selectedAddress);
      this.setContract(new ethers.Contract(this.walletAddress, this.abi, this.signer));
      const balance = await this.defaultProvider.getBalance(this.walletAddress);
      this.setWalletBalance(ethers.utils.formatEther(balance));
      this.nftsOwned = await this.defaultContract.tokensOfOwner(this.walletAddress);
      this.setNftsOwnedResult(this.nftsOwned);
      this.setChainId(parseInt(this.provider.network.chainId));
      this.setContract(new ethers.Contract(this.address, this.abi, this.signer));

      if (this.chainId !== configData.chainId) {
        const params = [{
          chainId: configData.chainIdHex,
          chainName: configData.chainName,
          nativeCurrency: {
            name: configData.currency.name,
            symbol: configData.currency.symbol,
            decimals: configData.currency.decimals
          },
          rpcUrls: [configData.rpcUrl],
          blockExplorerUrls: [configData.blockExplorerUrl]
        }]
        window.ethereum.request({ method: 'wallet_addEthereumChain', params })
          .then(() => console.log('Success'))
          .catch(error => console.log("Error", error.message))
        this.uiStoreRef.setIsCorrectWallet(false);
        this.showNotification(
          !this.uiStoreRef.notificationBar.isVisible,
          "Wrong Chain ID, connect to xDai Smart Chain Mainnet!",
          NotificationTypeEnum.ERROR
        )
      } else {
        this.uiStoreRef.setIsCorrectWallet(true);
        this.getOwnNftDetailsArray()
        this.showNotification(
          !this.uiStoreRef.notificationBar.isVisible,
          "Successfully connected to xDai with: ..." + this.walletAddress.slice(-8),
          NotificationTypeEnum.SUCCESS
        )
      }

      this.provider.on("accountsChanged", accounts => {
        this.setWalletBalance(ethers.utils.formatEther(balance));
      });

      // Subscribe to chainId change
      this.provider.on("chainChanged", chainId => {
        this.setChainId(parseInt(chainId));
        if (this.chainId !== configData.chainId) {
          this.showNotification(
            !this.uiStoreRef.notificationBar.isVisible,
            "Wrong Chain ID, connect to xDai Smart Chain Testnet!",
            NotificationTypeEnum.ERROR
          )
        }
      });

      // Subscribe to provider connection
      this.provider.on("connect", info => {
        console.log(info);
      });

      // Subscribe to provider disconnection
      this.provider.on("disconnect", error => {
        console.log(error);
      });

    } catch (e) {
      console.log(e);
    }
  };



  purchaseNft = async () => {
    const purchasePrice = this.sliderValue * this.currentPrice;
    let successfulPurchase = false;
    try {
      let tx = await this.contract.mint(this.sliderValue, {
        from: this.walletAddress,
        value: ethers.utils.parseEther(purchasePrice.toString()).toString(),
      });
      await tx.wait();
      successfulPurchase = true;
    } catch (e) {
      console.log('Transaction failed');
      console.log(e);
    }
    if (successfulPurchase) {
      const balance = await this.defaultProvider.getBalance(this.walletAddress);
      this.setWalletBalance(ethers.utils.formatEther(balance));
      this.nftsOwned = await this.defaultContract.tokensOfOwner(this.walletAddress);
      this.setNftsOwnedResult(this.nftsOwned);
      const amountSold = await this.defaultContract.totalSupply();
      this.setSoldNfts(String(amountSold));

      this.provider.on("accountsChanged", accounts => {
        this.setWalletBalance(ethers.utils.formatEther(balance));
      });

      this.showNotification(
        !this.uiStoreRef.notificationBar.isVisible,
        "Successfully purchased LegendAries!",
        NotificationTypeEnum.SUCCESS
      )
    }
    else {
      this.showNotification(
        !this.uiStoreRef.notificationBar.isVisible,
        "Failed to purchased LegendAries!",
        NotificationTypeEnum.ERROR
      )
    }
  };

  giveawayNft = async () => {
    let successfulPurchase = false;
    try {
      let tx = await this.contract.mint({ from: this.walletAddress });
      await tx.wait();
      successfulPurchase = true;
    } catch (e) {
      console.log('Transaction failed');
      console.log(e);
    }
    if (successfulPurchase) {
      this.nftsOwned = await this.defaultContract.tokensOfOwner(this.walletAddress);
      this.setNftsOwnedResult(this.nftsOwned);
      const amountSold = await this.defaultContract.totalSupply();
      this.setSoldNfts(String(amountSold));

      this.showNotification(
        !this.uiStoreRef.notificationBar.isVisible,
        "Successfully purchased LegendAries!",
        NotificationTypeEnum.SUCCESS
      )
    }
    else {
      this.showNotification(
        !this.uiStoreRef.notificationBar.isVisible,
        "Failed to purchased LegendAries!",
        NotificationTypeEnum.ERROR
      )
    }
  };

  showNotification = (condition, notificationMessage, notificationType) => {
    if (this.uiStoreRef) {
      if (condition) {
        this.uiStoreRef.setNotificationMessage(
          notificationMessage,
          notificationType
        )
      } else {
        const resetValue = notificationType === NotificationTypeEnum.ERROR
        this.uiStoreRef.setNotificationBarVisibility(resetValue)
        this.uiStoreRef.setNotificationMessage(
          notificationMessage,
          notificationType
        )
      }
    }
  }
}

export default ChainStore;
