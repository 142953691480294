import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from "react-i18next";
import NumberFormat from 'react-number-format';
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Balance = observer(() => {
    const { t } = useTranslation();
    const { chainStore, uiStore } = useStores();
    const { isCorrectWallet } = uiStore;
    const { nftsOwnedResult, walletBalance, walletAddress, connectToWallet } = chainStore;

    return (
        <div className={styles["balance__container"]}>
            {!walletAddress ?
                <div className={styles["balance__connect"]} onClick={() => connectToWallet()} >
                    {t("purchaseNfts.connect")}
                </div>
                :
                <>
                    {isCorrectWallet ?
                        <>
                            <div className={styles["balance__block"]}>
                                {t("purchaseNfts.balance")}
                                <span className={styles["balance__block--sub-text"]}>
                                    <NumberFormat
                                        value={parseFloat(walletBalance).toFixed(4)}
                                        displayType={'text'}
                                        thousandSeparator={true} />
                                    {' MOVR'}
                                </span>
                            </div>
                            <div className={styles["balance__block"]}>
                                {t("purchaseNfts.nftsOwned")}
                                <span className={styles["balance__block--sub-text"]}>{nftsOwnedResult.length}</span>
                            </div>
                        </>
                        :
                        <div className={styles["balance__connect"]} onClick={() => connectToWallet()} >
                            {t("purchaseNfts.wrongChainId")}
                        </div>
                    }
                </>
            }
        </div>
    )
});

export default Balance;
