import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const Roadmap = observer(() => {
    const { t } = useTranslation();
    const roadmapArray = t("roadmap.blocks", { returnObjects: true });

    return (
        <div id="roadmap" data-aos="zoom-in" className={styles["component-container"]}>
            <h2>
                {t("roadmap.title")}
            </h2>
            <div className={styles["roadmap_wrapper"]}>
                {roadmapArray.map((block, i) => {
                    return (
                        <div key={i} className={styles["roadmap_block"]}>
                            <img className={i === 0 ? styles["roadmap_logo-on"] : styles["roadmap_logo-off"]} alt={"Legend Art Logo"} src={"images/legend-art-logo.svg"} />
                            <div className={styles["roadmap_title"]}>
                                {block.title}
                            </div>
                            <div className={styles["roadmap_description"]}>
                                {block.description}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
});

export default Roadmap;
