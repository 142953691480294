import { observer } from "mobx-react";
import React from 'react';
import styles from "./index.module.scss";

const Socials = observer(() => {

    return (
        <div className={styles["socials-container"]}>
            <a href="https://t.me/LegendArtCommunity">
                <img alt="Telegram icon" className={styles["social-icons"]} src={"images/telegram.svg"} />
            </a>
            <a href="https://discord.gg/hKX3yPBC87">
                <img alt="Discord icon" className={styles["social-icons"]} src={"images/discord.svg"} />
            </a>
            <a href="https://twitter.com/LegendArtNFT">
                <img alt="Twitter icon" className={styles["social-icons"]} src={"images/twitter.svg"} />
            </a>
        </div>
    )
});

export default Socials;