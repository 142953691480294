import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import Konami from 'react-konami-code';
import Particles from 'react-particles-js';
import AboutLegendAries from "../../components/about-legend-aries";
import Header from "../../components/header";
import Team from "../../components/team";
import PurchaseNfts from "../../components/purchase-nfts";
import Roadmap from "../../components/roadmap";
import Tokenomics from "../../components/tokenomics";
import { useStores } from "../../stores/RootStore";
import { default as commonStyles } from "./index.module.scss";

const Home = observer(() => {
    const { uiStore } = useStores();
    const { setHomeMounted } = uiStore;
    const [particlesOn, setParticlesOn] = useState(false);

    useEffect(() => {
        setHomeMounted(true);
        return () => { setHomeMounted(false); };
    });

    const easterEgg = () => {
        setParticlesOn(true);
    }

    return (
        <>
            <Konami action={easterEgg} />
            {particlesOn && <Particles className={commonStyles["particles"]} params={
                {
                    "particles": {
                        "number": {
                            "value": 25,
                            "density": {
                                "enable": false,
                                "value_area": 0
                            }
                        },
                        "color": {
                            "value": "#ffffff"
                        },
                        "shape": {
                            "type": "image",
                            "stroke": {
                                "width": 0,
                                "color": "#000000"
                            },
                            "polygon": {
                                "nb_sides": 5
                            },
                            "image": {
                                "src": "images/legend-art-logo.svg",
                                "width": 1,
                                "height": 1
                            }
                        },
                        "opacity": {
                            "value": 1,
                            "random": true,
                            "anim": {
                                "enable": true,
                                "speed": 1,
                                "opacity_min": 0,
                                "sync": false
                            }
                        },
                        "size": {
                            "value": 50,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 4,
                                "size_min": 0.3,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": false,
                            "distance": 150,
                            "color": "#ffffff",
                            "opacity": 0.4,
                            "width": 1
                        },
                        "move": {
                            "enable": true,
                            "speed": 1,
                            "direction": "none",
                            "random": true,
                            "straight": false,
                            "out_mode": "out",
                            "bounce": false,
                            "attract": {
                                "enable": true,
                                "rotateX": 600,
                                "rotateY": 600
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "bubble"
                            },
                            "onclick": {
                                "enable": true,
                                "mode": "repulse"
                            },
                            "resize": true
                        },
                        "modes": {
                            "grab": {
                                "distance": 400,
                                "line_linked": {
                                    "opacity": 1
                                }
                            },
                            "bubble": {
                                "distance": 250,
                                "size": 0,
                                "duration": 2,
                                "opacity": 0,
                                "speed": 3
                            },
                            "repulse": {
                                "distance": 400,
                                "duration": 0.4
                            },
                            "push": {
                                "particles_nb": 4
                            },
                            "remove": {
                                "particles_nb": 2
                            }
                        }
                    },
                    "retina_detect": true
                }
            } />}
            <div className={commonStyles["container"]}>
                <Header />
                <AboutLegendAries />
                <Tokenomics />
                <Roadmap />
            </div>
        </>
    )
});

export default Home;