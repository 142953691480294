import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Leaderboard = observer(() => {
    const { t } = useTranslation();
    const { uiStore } = useStores();
    const { modalOpen, setModalOpen } = uiStore;

    return (
        <div>
            <div className={styles["balance__container"]}>
                <div className={styles["balance__connect"]} onClick={() => setModalOpen(!modalOpen)} >
                    {t("navbar.leaderboard")}
                </div>
            </div>
        </div>
    )
});

export default Leaderboard;
