export const TRANSLATIONS_EN = {
    navbar: {
        about: "ABOUT",
        buy: "BUY",
        faq: "FAQ",
        tokenomics: "TOKENOMICS",
        team: "TEAM",
        roadmap: "ROADMAP",
        leaderboard: "LEADERBOARD",
        menu: "MENU",
    },
    header: {
        title1: "LEGEND ART",
        title2: "Where Creativity meets Utility",
        description1:
            "Welcome to the next generation of generative art.  Unlike other projects, Legend Art Collections focus on providing not only amazing visual artwork, but will introduce unique utility attached to each collection. ",
        description2:
            "Right now the NFT industry is thriving and it is an exciting time for both artists and collectors alike.  Join us on this journey as we bring cutting edge tech to the Moonriver network!   ",
        tv_text: ">>> CLICK ME <<<",
    },
    purchaseNfts: {
        title: "PURCHASE COLLECTION I: LEGENDARIES",
        sold: "SOLD",
        balance: "BALANCE",
        nftsOwned: "NFTS OWNED",
        connect: "CONNECT",
        connectToWallet: "CONNECT TO WALLET",
        purchaseLegendAries: "PURCHASE LEGENDARIES",
        wrongChainId: "TRY AGAIN",
        acceptTos: "YOU MUST ACCEPT OUR TOS",
        disclaimer:
            "By purchasing Legend Art you agree that you are only purchasing an NFT. Any potential community rewards are dependant on the total supply of Legend Art having been sold out within 6 months from the start of sale. In the event that the total supply of Legend Art have not been sold out within 6 months from start of sale, no community rewards will be given out.",
    },

    aboutLegendAries: {
        title: "COLLECTION I: LEGENDARIES",
        description1:
            "Collection I: LegendAries will be the first of many exciting collections created by our extremely talented artist, Hato Mato.  They will be the genesis launch of our series and will bring with them the ability to farm our native token, LGD.  Unlike a traditional LP farm, these NFTs will be the sole asset required to stake in order to earn LGD, which will be the backbone of the Legend Art ecosystem.",
        description2:
            "Each LegendAries NFT is assigned a special attribute upon generation: an OVERALL FARMING POWER, calculated based on each layers' rarity.  Not only do you get to collect visually stunning artwork, but the rarity won't just be an arbitrary footnote, it will dictate how proficient your team of LegendAries is at farming the LGD token! ",
        description3:
            "This first collection being our series' genesis launch will also give these NFTs added utility in the future, as they will be able to team up with future collections to do extraordinary things.  Be sure to check the leaderboard when you mint to see where your LegendAries NFTs stand!  Did we mention certain attributes will come with special airdrops in the future as well?",
    },
    rangeSlider: {
        title: "NFTs to purchase",
        total: "Total : ",
    },
    roadmap: {
        title: "ROADMAP",
        blocks: [
            {
                title: "LegendAries Release",
                description:
                    "LegendAries will go on sale for 1 MOVR each.  Each wallet will be able to stake 15 NFTs in the farm to earn LGD tokens.  Remember, the stronger their power, the more efficient they are at collecting LGD!",
            },
            {
                title: "Marketplace",
                description:
                    "Legend Art collections will have its own unique, dedicated marketplace that will provide robust search and filter features so that collectors can easily find their favorite and powerful NFTs.",
            },
            {
                title: "LGD Farm",
                description:
                    "After the collection has reached 50% sold, the farm will open and everyone will be able to begin earning their LGD tokens.  Early mover advantage!",
            },
            {
                title: "Next collection",
                description:
                    "Our talented artist is hard at work designing the next collection, and our Solidity team is cooking up cutting edge utility to add to it!",
            },
        ],
    },
    tokenomics: {
        title: 'TOKENOMICS',
        coin: '$LGD',
        supply: 'Max supply:',
        maxSupply: ' 1,000,000 LGD',
        items: [
            { description: 'Purchase Special Edition NFTs' },
            { description: 'Spend for pre-sale access to later collections' },
            { description: 'Highest yield multiplier in farm' },
        ],
    },
    team: {
        title: "TEAM",
        members: [
            {
                image: "",
                name: "Peter - Full Stack Developer",
                quote: "Who knew that pressing keys in the right order, could change the world",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
            {
                image: "",
                name: "",
                quote: "Everything You Imagine is real",
                description: "After 14 years of building brands, designing product lines and games for projects focused primarily on generating profits for only themselves, I am happy that now i can use my experience to build a legacy which makes the world better place thanks to the unique connection of art and technology. How exciting is that?"
            },
            {
                image: "",
                name: "",
                quote: "Innovation is the unrelenting drive to break the status quo and develop anew where few have dared to go",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
            {
                image: "",
                name: "Zac - Marketing Strategist",
                quote: "The world + art = the world",
                description: "Ever since I was a little kid I have been obsessed with the idea of using software to solve practical problems. I strongly believe in the power of programming to transform and improve the lives of people around the world."
            },
        ]
    },
    faq: {
        title: "FREQUENTLY ASKED QUESTIONS",
        items: [
            {
                question: "Will I be able to see my NFT right when I purchase it",
                answer:
                    "YES, you will be able to see which NFT you have purchased and all of its attached metadata the moment you purchase it!  The contract is assigned a randomized mint order that is hidden privately in the solidity code itself.  Not even the smartest sleuths will be able to determine the mint order.",
            },
            {
                question: "Will rarities be disclosed before launch?",
                answer:
                    "No, part of the excitement will be checking the leaderboard and marketplace after you mint and determining which attributes provide the most power and figuring out how to best set up your farming team!",
            },
            {
                question: "Will the value of my LegendAries increase?",
                answer:
                    "LegendAries are NFTs designed as collectible trading cards on the Moonriver Network. Just as any other piece of art, the price fully depends what people are willing to pay, and the art you value the highest may for others have a lower value. Just as buying any piece of art, you can't know if the price will stay stable, increase or decrease.",
            },
            {
                question:
                    "What happens if not all 2500 LegendAries have been sold within 6 months?",
                answer:
                    "In the event that not all LegendAries have been sold in 6 months, the team will do its best to increase awareness and marketing. In the event this still doesn't lead to all being sold, as long as 50% have been sold, the farm will still open and Collection II will be released as planned.",
            },
            {
                question: "How do I interact with my LegendAries?",
                answer:
                    "You can view your LegendAries on this website and on any wallet with support, and they are tradable on our in-house marketplace and on open NFT Marketplaces supporting the ERC721 standard.",
            },
            // {
            //   question: "Can I, in advance, know which LegendArt I will get?",
            //   answer:
            //     "Before a LegendArt has been minted, all information except for the SHA-256 hash belonging to the image is unrevealed. After a purchase has been made, the LegendArt repository is automatically updated with the corresponding image and stats with a few minutes.",
            // },
            // {
            //   question:
            //     "Will LegendArt not get anything if all 10000 NFTs have been sold within 6 months?",
            //   answer:
            //     "Once the community reward pool (2000 BNB) has been filled up, LegendArt can withdraw any excess funds. As an example, if there is 2100 BNB on the contract then LegendArt are able to withdraw a maximum of 100 BNB during the first 6 months. If there is 1900 BNB, LegendArt cannot withdraw any funds from the contract during the first 6 months. Should not all 10000 NFTs have been sold within 6 months, then the community reward system has been unsuccessful which means no community rewards are paid out and LegendArt can withdraw all funds currently in the contract.",
            // },
            // {
            //   question: "I won! How do I claim my community reward?",
            //   answer:
            //     "You can claim your community reward on the website within 6 months from start of sale, if the community reward program was successful. To collect your community reward, input the slot number and press the Claim Reward button. To successfully claim, the winner address and the address you are transacting from must match.",
            // },
            // {
            //   question:
            //     "What happens if there are unclaimed community rewards after six months from start of sale?",
            //   answer:
            //     "Any unclaimed funds left on the contract 6 months from the start of sale will also belong to the team.",
            // },
            // {
            //   question: "What is the code?",
            //   answer: "Up, Up, Down, Down, Left, Right, Left, Right, B, A.",
            // },
        ],
    },
};
