import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Modal = observer(() => {
    const [cards, setCards] = useState([]);
    const [showOwnCards, setShowOwnCards] = useState(false);
    const { uiStore, chainStore } = useStores();
    const { modalOpen, setModalOpen } = uiStore;
    const { sortedNftDetailsArray, soldNfts, sortedOwnNftDetailsArray, connectToWallet } = chainStore;
    const fetchSize = 10;
    const logo = require("./1.png")

    const fetchMoreData = () => {
        if (cards.length - 1 + fetchSize > soldNfts) {
            setCards([...cards, ...sortedNftDetailsArray.slice(cards.length, cards.length + soldNfts)])
        } else {
            setCards([...cards, ...sortedNftDetailsArray.slice(cards.length, cards.length + fetchSize)])
        }
    };

    useEffect(() => {
        setCards(sortedNftDetailsArray.slice(0, fetchSize));
    }, [sortedNftDetailsArray])

    return (
        modalOpen &&
        <>
            <div id="scrollableDiv" className={styles["modal__container"]}>
                <div className={styles["modal__checkbox-container"]} onClick={() => { sortedOwnNftDetailsArray.length === 0 && connectToWallet(); setShowOwnCards(!showOwnCards) }}>
                    <span className={styles["modal__checkbox-container-text"]}>{!showOwnCards ? "SHOW YOUR NFTS ONLY" : "SHOW ALL PURCHASED NFTS"}</span>
                </div>
                {!showOwnCards ?
                    <InfiniteScroll
                        className={styles["modal__scroll"]}
                        dataLength={cards.length}
                        next={fetchMoreData}
                        hasMore={cards.length < soldNfts}
                        scrollableTarget="scrollableDiv"
                    >
                        {cards &&
                            cards.map((result, i) => (
                                <div className={styles["modal__block"]} key={i}>
                                    <img
                                        src={require(`./${i + 1}.png`).default}
                                        alt={result.tokenId}
                                    />
                                    <div className={styles["modal__nft"]}>
                                        <span className={styles["modal__description"]}>Farm Power: {result.power}</span>
                                        <span className={styles["modal__description"]}>Owner: {`${result.owner.slice(0, 4)}...${result.owner.slice(38, 42)}`}</span>
                                        <span className={styles["modal__description"]}>Token ID: {result.tokenId}</span>
                                    </div>
                                    <div className={styles["modal__rank-container"]}>
                                        <div className={styles["modal__rank-image"]}>
                                            {i + 1}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </InfiniteScroll>
                    :
                    <>
                        {sortedOwnNftDetailsArray &&
                            sortedOwnNftDetailsArray.map((result, i) => (
                                <div className={styles["modal__block"]} key={i}>
                                    <img
                                        src={result.image}
                                        alt={result.tokenId}
                                    />
                                    <div className={styles["modal__nft"]}>
                                        <span className={styles["modal__description"]}>Farm Power: {result.power}</span>
                                        <span className={styles["modal__description"]}>Owner: {`${result.owner.slice(0, 4)}...${result.owner.slice(38, 42)}`}</span>
                                        <span className={styles["modal__description"]}>Token ID: {result.tokenId}</span>
                                    </div>
                                    <div className={styles["modal__rank-container"]}>
                                        <div className={styles["modal__rank-image"]}>
                                            {i + 1}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                }
            </div>
            <div className={styles["modal__background"]} onClick={() => setModalOpen(false)}></div>
        </>
    )
});

export default Modal;