import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const Tokenomics = observer(() => {
    const { t } = useTranslation();
    const [value, setValue] = useState(0)
    const tokenomicsArray = t("tokenomics.items", { returnObjects: true });

    useEffect(() => {
        setTimeout(() => {
            value === 2 ? setValue(0) : setValue(value + 1)
        }, 2000);
    }, [value])


    return (
        <div id="tokenomics" className={styles["component-container"]}>
            <div data-aos="zoom-in" className={styles["tokenomics"]}>
                <h2>
                    {t("tokenomics.title")}
                </h2>
                <div className={styles["tokenomics__content"]}>
                    <div className={styles["tokenomics__coin"]}>
                        <img className={styles["tokenomics__coin__image"]} alt={"Legend Art Tokenomics"} src={"images/coin.gif"} />
                    </div>
                    <div className={styles["tokenomics__description"]}>
                        <div className={styles["tokenomics__description__supply"]}>
                            {t("tokenomics.supply")}
                            <span className={styles["tokenomics__description__supply__max"]}>
                                {t("tokenomics.maxSupply")}
                            </span>
                        </div>
                        <ol>
                            {tokenomicsArray.map((item, i) => {
                                return (
                                    <li key={i} className={styles[value === i ? "tokenomics__description__block__animation" : "tokenomics__description__block"]}>
                                        <span className={styles["tokenomics__description__block__text"]}>
                                            {item.description}
                                        </span>
                                    </li>
                                )
                            })}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Tokenomics;
