import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from "../../components/button";
import styles from "./index.module.scss";

const Header = observer(() => {
    const { t } = useTranslation();

    return (
        <div id="header" className={styles["component-container"]}>
            <div >
                <div data-aos="zoom-in">
                    <h2 className={styles["title-1"]}>{t("header.title1")}</h2>
                    <div className={styles["title-2"]}>{t("header.title2")}</div>
                    <div className={styles["header__content"]}>
                        <div className={styles["header__image"]}>
                            <div className={styles["hero-image-container"]}>
                                {
                                    <img className={styles["hero-image"]} alt={"Legend Art Hero"} src={"legend-art-header.png"} />
                                }
                            </div>
                        </div>
                        <div className={styles["header__text"]}>
                            <div className={styles["header-text-container"]} name="tv-layer">
                                <span>{t("header.description1")}</span>
                                <span>{t("header.description2")}</span>
                                <Link className={styles["button"]} to="/#buy">
                                    <Button label={"BUY NOW"} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Header;
