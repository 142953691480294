import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const Team = observer(() => {
    const { t } = useTranslation();
    const teamArray = t("team.members", { returnObjects: true });

    return (

        <div id="team" data-aos="zoom-in" className={styles["container"]}>
            <h2>
                {t("team.title")}
            </h2>
            <div className={styles["team_container"]}>
                {teamArray.sort((a, b) => 0.5 - Math.random()).map((item, i) => {
                    return <div key={i} className={styles["team_card"]} >
                        <img className={styles["team_image"]} src={item.image} alt={item.alt} />
                        <div className={styles["team_text"]}>
                            <span className={styles["team_text-name"]}>{item.name}</span>
                            <div className={styles["team_text-wrapper"]}>
                                <span className={styles["team_text-title"]}>"{item.quote}"</span>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div >
    )
});

export default Team;
