import { utils } from "ethers";
import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import Button from "../button";
import RangeSlider from "../range-slider";
import styles from "./index.module.scss";
import configData from "../../constants/config-data.json";
import { useStores } from "../../stores/RootStore";
import { useEffect } from 'react';

const PurchaseNfts = observer(() => {
	const { t } = useTranslation();
	const { uiStore, chainStore } = useStores();
	const { isCorrectWallet } = uiStore;
	const { connectToWallet, defaultProvider, purchaseNft, walletAddress, sliderValue, walletBalance, soldNfts, maxNfts, getTotalSold, getNftDetailsArray, giveawayNft } = chainStore;

	const filter = {
		address: configData.address,
		topics: [
			utils.id("Transfer(address,address,uint256)")
		]
	}

	useEffect(() => {
		defaultProvider.on(filter, (log, event) => {
			getTotalSold();
		})
	}, [])

	return (
		<div id="buy" className={styles["component-container"]}>
			<div data-aos="zoom-in" className={styles["purchase-nft_container"]}>
				<h2>{t("purchaseNfts.title")}</h2>
				<div className={styles["purchase-nft_purchase"]}>
					<div className={styles["purchase-nft_image-container"]}>
						<img className={styles["purchase-nft_image"]} alt={"Kites of Freedom Minting"} src={"images/legendaries.gif"} />
					</div>
					<div className={styles["purchase-nft_purchase-container"]}>
						<div className={styles["purchase-nft_sold-title"]}>{soldNfts} / {maxNfts} {t("purchaseNfts.sold")}</div>
						{!walletAddress ?
							<></>
							:
							<div className={styles["purchase-nft_balance"]}>
								{t("purchaseNfts.balance")}
								<div className={styles["purchase-nft_balance--number"]}>
									<NumberFormat
										value={parseFloat(walletBalance).toFixed(4)}
										displayType={'text'}
										thousandSeparator={true} />
									{' MOVR'}
								</div>
							</div>
						}
						<RangeSlider />
						{!walletAddress ?
							<Button label={t("purchaseNfts.connectToWallet")} click={() => connectToWallet()} />
							:
							<>
								{isCorrectWallet ?
									<>
										{soldNfts !== maxNfts &&
											<>
												<Button label={sliderValue > 1 ? "PURCHASE " + sliderValue + " NFTS" : "PURCHASE " + sliderValue + " NFT"} click={() => purchaseNft()}></Button>
											</>
										}
									</>
									:
									<Button label={t("purchaseNfts.wrongChainId")} click={() => connectToWallet()}></Button>
								}
							</>
						}
					</div>
				</div>
			</div>
		</div>
	)
});

export default PurchaseNfts;
